@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
/* nav{
  transition: all 1s ease-in-out;
  animation: fadeIn 2s; 
}

@keyframes fadeIn {
  0% { opacity: 1; }
  100% { opacity: 0; }
} */
.head-main {
  width: 100%;
  height: 80px;
  background-color:#fff;
  position: absolute;
  padding: 20px;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 99;
  /* transition: all 0.5s ease-in-out; */
}
.head-main.scrolled{
  background-color: var(--primary-color) !important;
  position: fixed !important;
  top: 0 !important;
  width: 100%;
}
.avatar.placeholder{
  background-image: url('../../assets/placeholder.webp');
  background-size: cover;
  margin-left: 20px;
}
.auth-links{
  display: flex;
  font-size: 15px;
  margin-left: 20px;
  margin-top: 5px;
  font-weight: 500;
  color: #545353;
  /* transition: all 0.2s ease-in-out; */
}

@media screen and (max-width:600px) {
  .auth-links{
    text-align: center;
    margin-top: 10px;
  }
}