.form-top {
  position: relative;
  background-color: #545353;
  background-image: url();
  background-size: cover;
  padding: 50px 0;
  overflow: hidden;
}
.footer-logo {
  width: 150px;
  height: 150px;
}
.footer1 > p {
  padding: 20px 0;
  color: #545353;
  line-height: 1.8em;
  font-weight: 600;
}
.address{
  padding: 20px 0;
}
.address > p {
  color: #fff;
  cursor: pointer;
  font-size: 13px;
}
.address p > i {
  color: #fff;
  padding: 0 10px;
}
.address p > span {
  font-size: 12px;
}
.footer_sub-head{
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  padding: 15px 0 0 0;
}
.footer_ul{
  list-style: none;
  padding: 0;
}
.footer_links > a{
  font-size: 13px;
  color: #fff;
}
.footer2 > p{
  color: #ffff;
}
.subscribe{
  width: auto;
  border: 1px solid #aabea0;  
  border-radius: 5px;
  font-size: 13px;
}
.subscribe > input{
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  padding: 0 10px;
  color: #fff;
  font-weight: 400;
}
.subscribe > button{
  padding: 10px;
  background: transparent;
  color: #fff;
  border-left: 1px solid #aabea0;
  transition: all 0.2s ease-in-out;
}
.subscribe > button:hover{
  background-color: #aabea0;
  color: #545353;
}
.f_social > i{
margin: 0 10px;
color: #fff;
}
.f_social i > a{
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
}
.f_social .fb:hover > a{
  color: #4267b2;
}
.f_social .twt:hover > a {
  color: #00acee;
}
.f_social .linkin:hover > a {
  color: #0077b5;
}
.f_social .insta:hover > a {
  color: #8a3ab9;
}
.f_social .yt:hover > a {
  color: #ff0000;
}
@media (max-width: 991px) {
  .footer1 > p,
  .address > p {
    font-size: 14px;
  }
}

.footer-head {
  font-weight: 600;
  color: #aabea0;
  user-select: none;
}
.footer-link {
  list-style: none;
  padding: 0;
}
.footer-link > li {
  padding: 8px 0;
}
.footer-link li > a {
  font-weight: 600;
  text-decoration: none;
  color: #545353;
  transition: all 0.2s ease-in-out;
}
.footer-link li > a:hover {
  color: #c8b084;
}
.footer-link li a > i {
  padding: 0 10px;
}
.footer-btn {
  width: 45%;
  background-color: #c8b084;
  color: #fff;
  outline: none !important;
  border: none;
}
.footer-btn:hover,
.footer-btn:active {
  background-color: #aabea0;
  outline: none !important;
}
.footer-txtA {
  width: 100%;
  outline: none;
  border-color: #cdd3da;
  border-radius: 5px;
  padding: 10px;
}
.footer-btm {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #545353;
  padding: 10px;
}
.footer-btm .container > p {
  color: #aabea0;
  font-size: 12px;
  text-align: center;
}
.foo_line{
  width: 70%;
  height: 0.01rem;
  background-color: #aabea06e;
  margin: 0 auto;
  margin-bottom: 20px;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: none;
  box-shadow: none;
  outline: 0 none;
}
