.profile_div {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #eee;
  padding: 90px 0;
}

.card-ht {
  height: 235px !important;
}
.hegh {
  height: 33px !important;
}
.cls-head {
  text-align: center;
}
.btn {
  margin: 5px;
  font-size: 14px !important;
}
.con {
  margin: 4px;
}
.join {
  /* float: right !important; */
  background-color: #4d8c57;
  color: white;
  padding: 5px;
  width: 150px;
  position: relative;
  font-size: 14px;
  border-radius: 6px;
  top: 20px;
  bottom: 5px;
}
.bolder {
  color: black;
  font-size: 15px;
  margin: 0px 0px 8px;
  padding: 0 10px 0 0;
  font-weight: bolder;
}
.edit-pro {
  color: white;
  background-color: black;
  border: none;
  outline: none;
  font-size: 12px;
}
.saved {
  margin: 0px auto;
  text-align: center;
  background-color: #000000;
  color: white;
  left: 44%;
}
.crop-mode {
  background-color: white;
}

.p-dialog-content {
  overflow: hidden;
}
.profileImg {
  width: 150px;
  height: 150px;
  align-items: center;
  margin: 0px auto;
  padding: 10px;
  margin-top: 15px;
  border-radius: 50%;
}
.user-profile {
  margin: 0px auto;
  text-align: center;
  width: 160px;
  height: 180px;
}
.ex_cards {
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow-x: hidden;
}
.ex_cards::-webkit-scrollbar {
  height: 7px;
  width: 5px;
}

.ex_cards::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #c0d2b7;
}

.ex_cards::-webkit-scrollbar-thumb {
  background-color: #c0d2b7;
  outline: 1px solid #b7cbae;
}

.pro_cards {
  margin: 10px;
}
.profile_icons {
  padding: 20px;
}
.profile_icons i p {
  padding: 15px;
}
.ico {
  padding: 5px;
  padding-top: -8px !important;
}

.arrow {
  cursor: pointer;
  margin-left: 10px;
  transform: left(45deg);
  animation-delay: 2s;
  font-size: 35px;
  float: left;
  color: #c0d2b7;
}
.arrow :hover {
  font-size: 35px;
  color: #322b2b;
}
.log_btn {
  border: 1px solid#322B2B !important;
}

.profile_btn {
  top: 90%;
  transform: translate(-50%, -50%);
  background-color: #252525;
  color: #fff;
  transition: all 0.2s ease-in-out;
  overflow: hidden !important;
}
.heads {
  font-size: 20px;
  text-align: left;
}
.present-card {
  padding: 10px;
}
.pro-btn {
  position: relative;
  padding: 5px;
  width: 130px;
  margin: 5px;
  height: 38px;
  color: #eeeaea;
  background-color: #252525;
  transition: all 0.2s ease-in-out;
  overflow: hidden !important;
  border-radius: 5px;
  text-decoration: none;
}
.profile_btn::after,
.profile_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.pro-btn::after,
.pro-btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.pro-btn::before {
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.pro-btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.pro-btn:hover {
  color: #080000 !important;
  background-color: #c0d2b7;
}
.view_btn {
  width: 100px;
  height: 45px;
  border-radius: 5px;
  background-color: #252525;
  color: #fff;
  text-decoration: none;
  margin-top: 5.5rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  margin: 10px;
  z-index: 1;
  border: none;
}
.view_btn::after,
.view_btn::before {
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.view_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.view_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.view_btn:hover {
  color: #252525;
  font-weight: 500;
  background-color: #c0d2b7;
}
.slick-slide img {
  height: 100px;
  background-color: white;
}
.profile_card {
  position: relative;
  overflow: hidden;
}
.profile_card::-webkit-scrollbar {
  height: 7px;
  width: 5px;
}

.profile_card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #c0d2b7;
}

.profile_card::-webkit-scrollbar-thumb {
  background-color: #c0d2b7;
  outline: 1px solid #c0d2b7;
}
.therapy_img_ {
  width: 100%;
  height: auto;
  margin: 0px auto;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  text-align: center;
}
.therapy_img_ > img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
  margin: 0px auto;
  text-align: center;
}
.therapy_info {
  position: relative;
}
.therapy_info > p {
  text-align: center;
  line-height: 0px;
  font-size: 15px;
}
.therapy_status > p {
  line-height: 5px;
}
.therapy_info > .category {
  color: #747474;
  font-size: 13px;
}
.line_1 {
  position: absolute;
  width: 1px;
  height: 60%;
  top: 50%;
  left: 0;
  background-color: #d7d7d7;
  transform: translateY(-50%);
}
.therapy_status {
  position: relative;
  text-align: center;
  align-items: center;
}
.therapy_status > .therapist_ {
  font-weight: 500;
}
.therapy_status > .desc {
  font-size: 12px;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.therapy_status > .desig {
  font-size: 13px;
  color: #747474;
}
.therapy_status > .play_ {
  position: absolute;
  text-align: center;
  right: 20px;
  bottom: 0;
  width: auto;
  color: white;
  font-size: 10px;
  border-radius: 6%;
  transition: all 0.2s ease-in-out;
}
.therapy_status > .play_ {
  background-color: #4d8c57;
}
.play_ > i {
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.play_ {
  text-align: center;
  align-items: center;
  padding: 2px;
  height: auto;
  padding-right: 5px;
}
.livenow {
  position: absolute;
  display: flex;
  width: 50px;
  height: 25px;
  right: 9px;
  top: 5px;
  padding: 5px;
  background-color: transparent;
  border-radius: 5px;
  bottom: 20px;
}
.livenow > p {
  font-size: 12px;
  margin-left: 5px;
  color: #252525;
}
.edit-cont {
  padding-top: 100px;
}
.pro-inst {
  display: block;
  width: 150px;
  height: 150px;
  background-color: #7777;
  margin-left: 20px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 50px !important;
  overflow: hidden;
}
.add_form {
  width: 55%;
  margin: 0px auto;
  margin-bottom: 50px;
}
.save-profile {
  text-align: center;
  margin: 0px auto !important;
  background-color: #252525 !important;
  color: white !important;
  width: 150px;
  border: none;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}
.save-profile:hover {
  background-color: #b7cbae;
  color: black;
  font-weight: 600;
}
.add_form label {
  font-size: 15px;
  color: #737475;
}
.btn-edit-pro {
  background-color: #b7cbae;
  color: black;
  font-weight: 500;
  float: right;
  border: none;
}
.btn-edit-pro:hover {
  background-color: #252525;
  color: white;
}
.slider {
  text-align: center;
  width: 100%;
  height: 130px;
  margin: 0px auto;
  text-align: center;
  padding: 5px;
  font-size: 16px;
}
.paraph {
  margin-top: 6% !important;
}

.let {
  font-size: 16px;
  margin-top: 7%;
}

.live-dot {
  width: 10px;
  height: 10px;
  background-color: #ff5252;
  border-radius: 50%;
  transform: scale(1);
  animation: pulse-black 2s infinite;
  margin-top: 3px;
}
.add_form p {
  color: red !important;
}
@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.854);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0.186);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.current_session_title {
  font-size: 15px;
}
.Carousal-div {
  border: none;
  outline: none;
  font-size: 16px;
  text-align: center;
  color: #25303c;
  cursor: pointer;
  overflow: hidden !important;
}

.hover03 figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.3s ease-in-out;
}
.hover03 figure:hover img {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
.profile-table th {
  font-size: 15px;
  color: #252525;
  font-weight: 500;
}
.profile-table td {
  font-size: 13px;
}
.slick-dots {
  width: 200px !important;
  bottom: 35px !important;
  left: 50%;
  transform: translateX(-50%);
}
.slick-next:before {
  color: #b7cbae !important;
}
.slick-next:before,
.slick-prev:before {
  color: #b7cbae !important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
  width: 13px;
  height: 13px;
}
.slick-dots li button:before {
  width: 13px !important;
  height: 13px !important;
}
.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}
.slick-prev {
  left: -35px;
}
.slick-prev,
.slick-next {
  top: 40%;
}
.profile__ {
  padding-top: 20%;
  height: 89%;
}
@media screen and (max-width: 1200px) {
  .profile__ {
    height: auto;
    padding: 0;
  }
}
@media screen and (max-width: 786px) {
  .therapist_ {
    font-size: 11px;
  }
  .therapy_info > .category {
    line-height: 1.2em;
  }
  .therapy_info > p {
    font-size: 12px;
    padding-top: 0px !important;
  }
  .desig {
    line-height: 1.2em !important;
  }
  .slick-dots li button {
    height: 10px;
    width: 10px;
  }
  .therapy_info > p {
    text-align: center;
    line-height: 0px;
    font-size: 12px;
  }
  .join {
    width: 110px;
    font-size: 12px;
  }
}
#avatar_container-9692-30e6-0b9c {
  background-size: cover;
  height: 400ox;
  width: 400px;
}
.dialogue-cont {
  text-align: center;
  margin: 0px auto !important;
}
.vatar_loader-cb0 {
  background-color: white;
}
@media screen and (max-width: 600px) {
  .profile_card {
    height: 100%;
  }
  .pro_div p {
    font-size: 12px;
  }
  .card-title {
    font-size: 12px;
  }
  .therapy_info > h5 {
    margin-top: 10px;
  }
  .heads {
    font-size: 13px;
  }
  .profile-table th {
    font-size: 12px !important;
  }
  .line_1 {
    display: none;
  }
  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: -10px;
  }
  .profile_card {
    position: relative;
    height: auto;
    overflow: hidden;
  }

  .ex_cards {
    overflow-y: scroll;
    overflow-x: scroll;
    font-size: 12px;
  }
  .profile-table th {
    font-size: 14px;
  }
  .therapy_status > .play_ {
    position: unset;
  }
  .add_form input {
    font-size: 12px !important;
    color: black;
  }
  .add_form label {
    font-size: 14px !important;
  }
  .add_form {
    width: 90%;
  }
  .join {
    width: 111px;
    margin: 0px auto;
    text-align: center;
    position: unset;
    height: 35px;
    padding: 0;
  }

  .slider {
    height: auto;
  }
}
.image-pro {
  border-radius: 50%;
}
.pending_{
  text-align: center;
  padding: 5px 0;
  margin: 10px 0px;
  font-size: 13px;
  background-color: #dff1d6;
  width: 80%;
  display: block;
  border-radius: 5px;
  font-weight: 500;
}